import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../components/icon';
import Text from '../../../../../components/text';


const Item = ({ title, content, icon, variant }) => {
    const isFlexCol = (variant === 'flex-col')

    return (
        <div className={`flex pt-8 ${isFlexCol ? 'flex-col' : 'flex-row'}`}>
            

            <div className={`${!isFlexCol && 'mx-6'}`}>

                <Text
                    color
                    textSize
                    tailwind='font-medium text-gray-900 text-lg' >{title}</Text>

                <Text
                    textSize
                    tailwind={`${isFlexCol ? ' text-base ' : 'text-sm md:text-base'}`}>{content}</Text>
            </div>

        </div>
    );
};

export const propTypes = {
    variant: PropTypes.oneOf(['flex-row', 'flex-col'])
};



export default Item;