import React from 'react';
import PropTypes from 'prop-types';
import companylogo from '../images/grzlogo.png'
import companylogo2 from '../images/hic.png'
import companylogo3 from '../images/weeffect.png'
import Text from '../../../components/text';

const AppBar = ({variant,tailwind,className}) => {
    let variantStyle;

    const variantLight ='bg-white border-b border-gray-300'
    const variantDark = 'bg-gray-800'

    switch(variant){
        case 'light':
            variantStyle = variantLight;
            break
            default:
                variantStyle = variantDark;
                break
    }

return (
    <>
         
        <div className={`flex flex-col items-center justify-around h-16 w-full ${variantStyle} ${tailwind} ${className}`}
        >
            <Text type='heading'  className='leading-10 mb-4 text-white'>In Partnership with</Text>
            <div className='flex flex-row justify-around w-full'>
                <img className='h-20 w-36' src={companylogo}/>
                <img className='h-24 w-40' src={companylogo3}/>
                <img className='h-24 w-32' src={companylogo2}/>
                
            </div>
        </div>
    </>    
    );
};

AppBar.defaultProps ={
variant: 'dark',
}

AppBar.propTypes = {
    variant: PropTypes.oneOf(['dark','light']),
};

export default AppBar;