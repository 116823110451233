import React from 'react';
import Container from '../../../../components/container';
import Text from '../../../../components/text';
import Data from '../components/data'


const CenteredGrid = () => {
    return (
        <Container  tailwind='bg-gray-100'>
            <Text
                color
                textSize
                tailwind='uppercase text-teal-600 font-semibold'>
                Background</Text>
            <div className='md:max-w-2xl'>
                <Text
                     color
                    tailwind='text-3xl sm:text-4xl m-2 text-gray-900 mb-6'
                    type='heading'>URBAN PLANNING AND HOUSING OBSERVATORY
                    TOWARDS INCLUSIVE DEVELOPMENT.
                    </Text>
                <Text color tailwind='text-gray-900'>The urban planning and Housing Observatory is a tool that seeks to promote inclusive development and a systemic approach towards identification of community challenges as well as participatory planning that is inclusive and informed by authentic data which is citizen driven. 
The Civic Forum on Housing and Habitat Zambia (CFHHZ) a membership based Non-Governmental Organisation (NGO) operating in seven provinces of Zambia. It has a total of 32 members comprising of international organisations, local NGOs, grassroots organisations and housing cooperatives. The organisations’ vision is to have a Zambia with well-planned human settlements with access to housing auxiliary services whist its mission is to be plat form of organisations dealing in housing, environment and human settlements. 
 </Text>
            </div>

            <Data  variant='flex-row' tailwind='text-white' />

        </Container>
    );
};

export default CenteredGrid;