import React from 'react';
import Button from '../../components/button';
import IconButton from '../../components/iconbutton';
import Text from '../../components/text';
import TextInput from '../../components/textinput';
import { ReactComponent as TwitterSVG } from './images/twitter.svg'
import { ReactComponent as FacebookSVG } from './images/facebook.svg'
import { ReactComponent as YoutubeSVG } from './images/youtube.svg'
import { ReactComponent as InstagramSVG } from './images/linkedin.svg'
import { ReactComponent as SVG } from './images/socials.svg'
import { useHistory } from 'react-router-dom';

const Contact = ({ facebook, instagram, twitter, youtube, variant }) => {
    
    const history = useHistory()

    const isDarkVariant = variant === 'dark'

    const iconsStyle = `mx-2 text-gray-600 ${isDarkVariant ? 'hover:bg-gray-700' : 'hover:bg-gray-300'}`

    const navigate = (url) => {
        window.location.href = url
    }

    return (
        <div className='flex flex-col bg-white bg-white  md:flex-row py-32 '>
            <SVG className="w-full" />
            <div className=' px-12 py-32 md:w-3/4 w-full'>
                <Text type='heading' className='leading-10'>Want to keep up with Latest CFHHZ activities?</Text>
                <Text type='heading-large'><span className='text-indigo-700 py-4  md:mt-0 mt-8'> Follow us on Our Socials.</span></Text>
                <div className='flex mb-8 md:mb-0'>
                    {typeof twitter !== 'undefined' &&
                        <IconButton
                            hover
                            onClick={() => navigate(twitter)}
                            color
                            tailwind={iconsStyle}
                            hasBackground={false}
                            src={<TwitterSVG />}
                        />
                    }
                    {typeof facebook !== 'undefined' &&
                        <IconButton
                            color
                            hover
                            onClick={() => navigate(facebook)}
                            tailwind={iconsStyle}
                            hasBackground={false}
                            src={<FacebookSVG />}
                        />
                    }
                    {typeof youtube !== 'undefined' &&
                        <IconButton
                            color
                            hover
                            onClick={() => navigate(youtube)}
                            tailwind={iconsStyle}
                            hasBackground={false}
                            src={<YoutubeSVG />}
                        />
                    }
                    {typeof instagram !== 'undefined' &&
                        <IconButton
                            color
                            hover
                            onClick={() => navigate(instagram)}
                            tailwind={iconsStyle}
                            hasBackground={false}
                            src={<InstagramSVG />}
                        />
                    }
                </div>
            </div>
            

        </div>
    );
};

export default Contact;