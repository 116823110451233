import React from 'react';
import { ReactComponent as GlobeSVG } from '../../images/globe.svg'
import { ReactComponent as LawSVG } from '../../images/law.svg'
import { ReactComponent as PowerSVG } from '../../images/power.svg'
import { ReactComponent as TextSVG } from '../../images/text.svg'
import Item, { propTypes } from '../item'

const data = [{
    title: 'Introduction',
    content: 'CFHHZ is a human rights based organisations that promotes citizen participation in development. With Financial support from We Effect Zambia office and Habitat International Coalition , the organisation supports communities with several capacity building interventions that enhance citizens’ knowledge on their rights and their obligations in ensuring that they have access to their entitlements with regards to well-planned urban and rural communities, housing, safe habitat (environment), secure land tenure, access to services such as clean and safe drinking water, good sanitation, effective waste management, clean energy solutions (heating and cooking), and economic empowerment. The organisation employs the study circle methodology a peer knowledge exchange model that enhances community participation in problem identification and collective planning and implementation as a vehicle of community transformation. CFHHZ operates under the three pillars; ',

}, {
    title: 'Three Pillars',
    content: 'a.    Adequate Housing,  b.  Economic Empowerment and Financial inclusion and c.  Land, environment and climate change, the organisation uses evidence based advocacy to facilitate dialogue between citizens (rights holders) and duty bearers to ensure there is collective problem identification, planning and development of projects and programmes. Premised on the Seventh National Development (7NDP) aspirations of integrated development and leaving no one behind, CFHHZ conducts housing clinics and survey that inform its advocacy interventions. ',
}, {
    title: 'Process',
    content: 'This urban planning and housing observatory is tool that provides an interface between communities, service providers, local authorities, and Government. The communities will log in their issues using the web site, text messaging or voice call and submit their challenges. The submissions will be filtered in the background by CFHHZ technical experts and submitted to the relevant authorities (service providers, local authorities, or line Government Ministry. The system will adhere to strict international standards of communication will protect the privacy of individuals that make their submissions. The issues will be posted on to the web site and the status will be provided and monitored by CFHHZ till the matter is fully resolved. The observatory seeks to enhance citizen participation in governance and ensure that citizens voices are heard. We urge our communities to actively participate in the process and together we can achieve making Zambia a middle prosperous country where every Zambian Citizen both in the rural and urban centres has universal access to a decent and quality life. ',

}, ]

const Data = ({ tailwind, variant, threeColumn }) => {
    return (
        <div className={`${tailwind}`}>
            <div className={`grid grid-cols-1 ${threeColumn ? 'md:grid-cols-3 md:gap-16' : 'md:grid-cols-2'}`}>
                {
                    data.map(({ title, content}, index) => (
                        ( threeColumn ? (index < 3) : true) &&
                        <Item
                            variant={variant}
                            title={title}
                            content={content}
                            
                        />
                    ))
                }
            </div>
        </div>
    );
}


Data.defaultProps = {
    threeColumn: false,
}
Data.propTypes = {
    ...propTypes,
};

export default Data;