import React, { Fragment, useState, useEffect }from 'react'
import {axiosInstance} from '../../../config'
import Table from '../Table'
import Navbar from '../Navbar'
import Footer from '../footer'
import Sidebar from '../Sidebar/Sidebar'




const IssueLog = () => {
    const [posts, setPosts] = useState([])
    useEffect(() => {
    axiosInstance.get('/addissue')
    .then(res => setPosts(res.data))
    .catch(err => console.log(err))
})

const [isOpen, setIsOpen] = useState(false)

const toggle = () => {
    setIsOpen(!isOpen)
}
    return (
        <Fragment>  
            <Sidebar isOpen={isOpen} toggle={toggle}/>
           <Navbar toggle={toggle} />
           <Table 
                tableData={posts}
                headingColumns={['Issue', 'Institution', 'District/Province' , 'Date', 'Status']}
                title='Issue Log'
           />
            <Footer
            twitter="https://twitter.com/CFHHZambia"
            facebook='https://www.facebook.com/CFHHZ'
            instagram='https://www.linkedin.com/company/cfhhz'
            variant='dark'
           />
        </Fragment>
    )
}

export default IssueLog
