import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar'
import Footer from '../footer'
import Sidebar from './components/Sidebar/Sidebar'
import Pagination from './components/pagination/Pagination'
import Posts from './Posts'
import {axiosInstance} from '../../../config'
import BrandIcons from '../stats/brand-icons'
import Simple from '../stats/simple'
import Categories from '../stats/simple/Categories'


const AdminDashboard = ({history}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [posts, setPosts] = useState([])
    const [cats, setCats] = useState([])
 
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10)
    
  

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  
  
    useEffect(()=>{
        const fetchPosts = async () => {
          const res = await axiosInstance.get('/post')
          setPosts(res.data)
        }
  
        fetchPosts()
    },[])

    useEffect(()=>{
        const fetchCats = async () => {
          const res = await axiosInstance.get('/categories')
          setCats(res.data)
        }
  
        fetchCats()
    },[])
  
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)
  

    const paginate = ( pageNumber )=> setCurrentPage(pageNumber)

    
    return (
        <div className='bg-gray-100 h-screen'>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
           <Navbar toggle={toggle} />
           <div className='flex flex-col md:flex-row justify-between'>
            <Simple title={'Total Issues'} value={posts.length} />
            {/* <Categories className='flex flex-row' cats={cats}/> */}
           </div>
           <Posts posts={currentPosts} />
           <Pagination 
            postsPerPage={postsPerPage}
            totalPosts={posts.length }
            paginate={paginate}
            currentPage={currentPosts}
            />
            
           
            
        </div>
    )
}

export default AdminDashboard
