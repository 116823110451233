import React, { useState } from 'react'
import HeroSection from '../HeroSection'
import LowerSection from '../LowerSection'
import Contact from '../Contact'
import Section2 from '../Section2'
import Navbar from '../Navbar'
import Footer from '../footer/'
import Sidebar from '../Sidebar/Sidebar'
import AppBar from '../appbar/Appbar'


const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }


    return (
        <>  
           <Sidebar isOpen={isOpen} toggle={toggle}/>
           <Navbar toggle={toggle} />
           <HeroSection />  
           <Section2 />    
           <LowerSection /> 

           <Contact 
            twitter='https://twitter.com/CFHHZambia'
            facebook='https://www.facebook.com/CFHHZ'
            instagram='https://www.linkedin.com/company/cfhhz'
           />
            <AppBar variant='light'  tailwind=' py-20'/>
           <Footer
            twitter="https://twitter.com/CFHHZambia"
            facebook='https://www.facebook.com/CFHHZ'
            instagram='https://www.linkedin.com/company/cfhhz'
            variant='dark'
           />
        </>  
    )
}

export default Home
