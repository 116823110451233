import React from 'react'
import Text from '../../../components/text'
import { ReactComponent as SVG} from '../images/about.svg'

const AboutHero = () => {
    return (
        <div className='flex md:flex-row  flex-col'>
            <div className='flex justify-center align-center flex-col px-6'>
                <Text color tailwind='text-gray-100 md:mt-0 mt-24 mb-5' type='heading-large'>About</Text>
                <Text color tailwind='mb-5 text-gray-100' type='heading'>Want to learn more on the Urban Planning and Housing Observatory?</Text>
            </div>
            
            <SVG />
            
        </div>
    )
}

export default AboutHero
