import React, { useState } from 'react'
import Footer from '../footer'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar/Sidebar'
import AboutHero from './AboutHero'
import CenteredGrid from './centeredgrid/CenteredGrid'

const About = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Navbar />
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <AboutHero />
            <CenteredGrid  tailwind='bg-gray-100' />
            <Footer
            twitter="https://twitter.com/CFHHZambia"
            facebook='https://www.facebook.com/CFHHZ'
            instagram='https://www.linkedin.com/company/cfhhz'
            variant='dark'
           />
        </>
    )
}

export default About
