import React from 'react';
import Item from './item';

const Simple = ({title, value}) => {
    return (
        <div className='grid grid-cols-3 gap-4 p-4'>

            <Item 
            title={title}
            value={value}/>
            
        </div>
    );
};

export default Simple;