import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import IssueForm from './IssueForm'
import Sidebar from '../../Sidebar/Sidebar'

const AddIssue = ({ history }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    useEffect(() => {
        const userInfo = localStorage.getItem("userInfo")
        if (!userInfo) {
          history.push("/");
        }
      }, [

        history,
      ]);
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
           <Navbar toggle={toggle} />
            <IssueForm />

        </>
    )
}

export default AddIssue
