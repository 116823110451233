import React, { useState, useRef }from 'react';
import Card from '../../../components/card';
import Button from '../../../components/button';
import { ReactComponent as ImgSVG } from '../images/contactus.svg'
import DefaultAlert from '../../../components/alert'
import styled from 'styled-components';
import Text from '../../../components/text';
import Container from '../../../components/container'
import Navbar from '../Navbar'
import Footer from '../footer/'
import Sidebar from '../Sidebar/Sidebar';
import { axiosInstance } from '../../../config';




const Alert = styled(DefaultAlert)`
margin: 24px;
`

const Form = () => {
    const cardStyle = 'border border-gray mt-4 lg:mt-0 lg:w-full lg:ml-8 lg:max-w-2xl'
    const cardWrapperStyle = ` lg:w-full  px-8`
    const horizontalLine = ` w-full h-px bg-gray-300 my-8`
    const titleStyle = `font-bold text-gray-800 mt-4 text-sm`
    const [show3, setShow3] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [location, setLocation] = useState('')
    const [description, setDescription] = useState('')



   

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const changeOnClick = async (e) =>{
        e.preventDefault()

        const newPost = {
            name,
            phone,
            location,
            description,

        }

        setName('')
        setPhone('')
        setLocation('')
        setDescription('')
 


        try{
           const res = await axiosInstance.post('/post/add', newPost)
        }catch (err){
           
        }
        
    } 
    


    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
           <Navbar toggle={toggle} />
            <Container className='bg-gray-100 min-h-screen'>
                <Text color tailwind='mb-24 mt-10' type='heading-large'>Report on your current issue here in under <span className='text-indigo-500'>5 minutes</span></Text>
                <div className='lg:flex' >

                    <Card className={cardStyle}>
                        <form onSubmit={changeOnClick} method='POST' encType="multipart/form-data">
                            <div className={cardWrapperStyle}>
                                <Text color textSize tailwind={titleStyle} >  FullName </Text>
                                <input className='w-full
                                                border border-gray-300
                                                px-3 py-2
                                                rounded-lg
                                                shadow-sm
                                                focus:outline-none
                                                focus:boder-indigo-500
                                                focus:ring-1
                                                focus:ring-indigo-500 ' 
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type='text' placeholder='Full name' />

                                <Text color textSize tailwind={titleStyle} >  Phone </Text>
                                <input className='w-full
                                                border border-gray-300
                                                px-3 py-2
                                                rounded-lg
                                                shadow-sm
                                                focus:outline-none
                                                focus:boder-indigo-500
                                                focus:ring-1
                                                focus:ring-indigo-500 '
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                type='text'  />

                                <Text color textSize tailwind={titleStyle} >  Location </Text>
                                <input className='w-full
                                                border border-gray-300
                                                px-3 py-2
                                                rounded-lg
                                                shadow-sm
                                                focus:outline-none
                                                focus:boder-indigo-500
                                                focus:ring-1
                                                focus:ring-indigo-500 '
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                                type='text' />


                                <Text color textSize tailwind={titleStyle} >  Issue Description </Text>
                                <textarea className='w-full
                                                border border-gray-300
                                                px-3 py-2
                                                rounded-lg
                                                shadow-sm
                                                focus:outline-none
                                                focus:boder-indigo-500
                                                focus:ring-1
                                                focus:ring-indigo-500'
                                                onChange={(e) => setDescription(e.target.value)}
                                                value={description}
                                                placeholder='Your Community Issue'></textarea>
                                

                                {/* <Text color textSize tailwind={titleStyle} >  Category </Text> */}
                                
                                 
                         
                                
                            </div>
                            

                            <div className='w-full bg-white flex justify-end mt-8 px-8 py-4 rounded-b-lg'>
                                <button 
                                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                type="submit"
                                onClick={() => setShow3(!show3)} >
                                  Submit
                                </button>
                            </div>
                            <Alert
                                isShown={show3}
                                onCloseComplete={() => setShow3(false)}
                                title='Submitted Successfully'
                                content={`Your Issue has been sent for review.`}
                                variant='success' />
                        </form> 

                    </Card>
                    
                    <ImgSVG className="mt-24 md:m-0" /> 
                    
                    
                </div>
                
                <div className={horizontalLine} />

            
        
            </Container>
            
            <Footer
            twitter="https://twitter.com/CFHHZambia"
            facebook='https://www.facebook.com/CFHHZ'
            instagram='https://www.linkedin.com/company/cfhhz'
            variant='dark'
           />
        </>    
    );
};

export default Form;
