import React from 'react'
import { ReactComponent as SVG } from './images/reachout.svg'
import Text from '../../components/text';
import Button from '../../components/button';
import { Link } from 'react-router-dom';


const Section2 = () => {
    const button = 'bg-orange-500 mt-8 mb-8'
    return (
        <div className='bg-white flex flex-col lg:flex-row relative justify-around '>
            <SVG className='order-last lg:order-first' />
            <div className=' pl-10 lg:absolute lg:right-24 lg:top-80 pt-12 lg:pt-0 bg-transparent lg:flex-2 max-w-md'>
                <Text
                    color
                    tailwind='uppercase text-gray-500 tracking-tight font-medium pt-8'
                >Towards Inclusive Development - Vision 2030 </Text> 
                <Text
                    color
                    tailwind='text-gray-600 mt-4'
                    type='heading-medium'>URBAN PLANNING AND  HOUSING OBSERVATORY</Text>
                <Text
                    color
                    tailwind='mt-6 text-gray-500'>
                    The urban planning and Housing Observatory is a tool that seeks to promote inclusive development and a systemic approach towards identification of community challenges as well as participatory planning that is inclusive and informed by authentic data which is citizen driven... </Text>

                    <Link to='/about'>
                        <Button
                        tailwind={button}
                        >Learn More</Button>
                    </Link>
            </div>

        </div>
    )
}

export default Section2
