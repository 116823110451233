import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../../components/text';
import Card from '../../../../../components/card';


const Item = ({title, value}) => {
    return (
        <div className='flex flex-row justify-between'>
            <Card tailwind='p-4 justify-center flex flex-col'>
            
            <Text type='text-small'>{title}</Text>
            <Text type='heading-small'>{value}</Text>

            
            </Card>
            
        </div>
    );
};

Item.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

export default Item;