import React from 'react'
import {  HashRouter as Router, Switch, Route } from 'react-router-dom'

import Home from './Pages/landing/HomePage/Home'
import About from './Pages/landing/About/About'
import IssueLog from './Pages/landing/IssueLog/IssueLog'
import './App.css'
import Form from './Pages/landing/Form/Form'
import Login from './Pages/landing/Login/Login'
import AdminDashboard from './Pages/landing/Admin/AdminDashboard'
import AddIssue from './Pages/landing/Admin/components/AddIssue'
import Sidebar from './Pages/landing/Sidebar/Sidebar'




const App = () => {



  return (
    <Router >
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' component={About} />
        <Route path='/issuelog' component={IssueLog} />
        <Route path='/form' component={Form} />
        <Route path='/admin-login'  component={Login} />
        <Route path='/addissue'  component={({history}) => (
          <AddIssue history ={history}/>
        )} />
        <Route path='/sidebar'  component={Sidebar} />
        <Route path='/admin' 
          component={({ history }) => (
            <AdminDashboard history={history}/>
          )}
        />
      </Switch>
    </Router>
  )
  
}

export default App
